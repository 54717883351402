import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero"
import TeamList from "../components/team-list"
import Featured from "../components/featured"
import FeaturedDetails from "../components/featured-details"
import CallToAction from "../components/call-to-action"
import SEO from "../components/seo"

const TeamPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            teamMembers {
              name
              title
              # description
              imageName
              socials {
                email
                linkedIn
                facebook
                behance
                artStation
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Hero
        imagePath="hero/team.jpg"
        title="Wonder"
        coloredTitle="Team"
        description="Passionate and motivated people"
      />

      <TeamList
        title="A team with high Voltage"
        coloredWords={2}
        description="We are a group of individuals with a tremendous passion for video games, and highly motivated towards building quality titles for the iOS and Google Play stores."
        teamMembers={site.siteMetadata.teamMembers}
      />

      <Featured imagePath="featured/prom_fighter.jpg" large>
        <FeaturedDetails
          imagePath="games/perfect-fighter/logo.png"
          title="Play the role of a fighter"
          description="Take out all of the enemies and dance on them!"
          callToAction={
            <CallToAction
              text="Play it now!"
              href="https://apps.apple.com/us/app/perfect-fighter/id1507989617"
              blank
            />
          }
        />
      </Featured>

      <SEO title="Team" />
    </Layout>
  )
}

export default TeamPage
